import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "doneBox"
}
const _hoisted_2 = { class: "bodyItem" }
const _hoisted_3 = { class: "itemValue" }
const _hoisted_4 = { class: "bodyItem" }
const _hoisted_5 = { class: "itemValue" }
const _hoisted_6 = {
  key: 0,
  class: "doneButton"
}
const _hoisted_7 = {
  key: 1,
  class: "buttonBox"
}
const _hoisted_8 = { class: "buttonItem" }
const _hoisted_9 = { class: "buttonItem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonLoader = _resolveComponent("CommonLoader")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonButtonSecondary = _resolveComponent("CommonButtonSecondary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: _ctx.isDone
    ? ' '
    : 'Withdraw from FLIGHT balance'
    
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonLoader, {
        state: _ctx.withdrawState,
        isPlaceholder: ""
      }, null, 8, ["state"]),
      _createElementVNode("div", {
        class: _normalizeClass(['body', { bodyIsDone: _ctx.isDone }])
      }, [
        (_ctx.isDone)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _withDirectives(_createElementVNode("span", null, "Withdraw completed!", 512), [
                [_vShow, !_ctx.hideContent]
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "itemlabel" }, "Sum", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.data.sum) + " ", 1),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "i" }, "FLIGHT", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "itemlabel" }, "BEP20-address", -1)),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.data.bep20), 1)
        ])
      ], 2),
      (_ctx.isDone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_CommonButtonPrimary, { onClick: _ctx.closeHandler }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Done ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isDone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_CommonButtonSecondary, { onClick: _ctx.onBack }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Back ")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_CommonButtonPrimary, { onClick: _ctx.onSend }, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createTextVNode(" Accept and Withdraw ")),
                  _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString(`${_ctx.data.sum} FLIGHT`), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "title"]))
}