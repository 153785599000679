import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "noData" }
const _hoisted_2 = { class: "noData__title" }
const _hoisted_3 = {
  key: 0,
  class: "noData__text"
}
const _hoisted_4 = {
  key: 1,
  class: "noData__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("svg", { class: "noData__img" }, [
      _createElementVNode("use", { "xlink:href": "#no-data-icon" })
    ], -1)),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("No data")), 1),
    (_ctx.noDataText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.noDataText)), 1))
      : _createCommentVNode("", true),
    (_ctx.datatError)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('no-data-text')), 1))
      : _createCommentVNode("", true)
  ]))
}