import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modalProductDetails" }
const _hoisted_2 = {
  key: 0,
  class: "box"
}
const _hoisted_3 = { class: "text" }
const _hoisted_4 = {
  key: 1,
  class: "box"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    isProductDetails: true,
    title: _ctx.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.data.price)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "text text--bold" }, "Price:", -1)),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.data.price) + " USDT", 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.data.desc)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text text--bold" }, "Content:", -1)),
              _createElementVNode("p", {
                class: "text",
                innerHTML: _ctx.data.desc
              }, null, 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["name", "title"]))
}