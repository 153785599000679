import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modalTopUp" }
const _hoisted_2 = { class: "modalTopUp__amountBox" }
const _hoisted_3 = {
  key: 0,
  class: "inputErrorInfo"
}
const _hoisted_4 = {
  key: 0,
  class: "sumInfo"
}
const _hoisted_5 = { class: "i" }
const _hoisted_6 = {
  key: 1,
  class: "sumInfo"
}
const _hoisted_7 = { class: "i" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: 'Top up',
    isTopUpModal: true,
    onCloseModal: _ctx.closeModal,
    onOpened: _ctx.opened
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CommonInput, {
            modelValue: _ctx.formData.amount,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.amount) = $event)),
            maxlength: "10",
            required: "",
            style: 'font-weight: bold;',
            onKeypress: _ctx.amountKeydown,
            onInput: _ctx.clearUnNumberValuePerPaste,
            class: "modalTopUp__amount"
          }, _createSlots({
            default: _withCtx(() => [
              _cache[5] || (_cache[5] = _createTextVNode(" Amount ")),
              (_ctx.isDisableButton(_ctx.contractsList?.data.data).value && !_ctx.formData.isStake)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, "The amount exceeds the scope of the contract"))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, [
            (!_ctx.formData.isStake)
              ? {
                  name: "suffix",
                  fn: _withCtx(() => [
                    _cache[3] || (_cache[3] = _createTextVNode("FLIGHT"))
                  ]),
                  key: "0"
                }
              : {
                  name: "suffix",
                  fn: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createTextVNode("$"))
                  ]),
                  key: "1"
                }
          ]), 1032, ["modelValue", "onKeypress", "onInput"]),
          (!_ctx.formData.isStake)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "i" }, "=", -1)),
                _createTextVNode(_toDisplayString(+_ctx.sumInUsd.toFixed(8)) + " $", 1),
                _createElementVNode("span", _hoisted_5, "1 FLIGHT = " + _toDisplayString(_ctx.oneFlcInUsd) + " $", 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[7] || (_cache[7] = _createElementVNode("span", { class: "i" }, "=", -1)),
                _createTextVNode(_toDisplayString(+_ctx.sumInFlc.toFixed(8)) + " FLIGHT", 1),
                _createElementVNode("span", _hoisted_7, "1 $ = " + _toDisplayString(_ctx.oneUsdInFlc.toFixed(8)) + " FLIGHT", 1)
              ]))
        ]),
        _createVNode(_component_CommonButtonPrimary, {
          disabled: _ctx.isDisableButton(_ctx.contractsList?.data.data).value && !_ctx.formData.isStake
        || !(Number(String(_ctx.formData.amount).replace(',', '.')) > 0)
        ,
          class: "modalTopUp__button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.topUpContract()))
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("Top up")
          ])),
          _: 1
        }, 8, ["disabled"]),
        (_ctx.formData.isStake)
          ? (_openBlock(), _createBlock(_component_CommonButtonPrimary, {
              key: 0,
              disabled: _ctx.formData.amount,
              class: "modalTopUp__button modalTopUp__buttonRenewal",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.topUpContract(true)))
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" Legacy Renewal Pack ")
              ])),
              _: 1
            }, 8, ["disabled"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["name", "onCloseModal", "onOpened"]))
}