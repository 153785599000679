import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modalNewContract" }
const _hoisted_2 = { class: "modalNewContract__inputsBox" }
const _hoisted_3 = ["value", "id", "checked", "disabled"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "modalNewContract__amountBox" }
const _hoisted_6 = {
  key: 0,
  class: "inputErrorInfo"
}
const _hoisted_7 = {
  key: 1,
  class: "inputErrorInfo"
}
const _hoisted_8 = {
  key: 0,
  class: "sumInfo"
}
const _hoisted_9 = { class: "i" }
const _hoisted_10 = {
  key: 1,
  class: "sumInfo sumInfoStake"
}
const _hoisted_11 = { class: "i" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: 'New contract',
    isModalNewContract: true,
    onCloseModal: _ctx.closeModal,
    onOpened: _cache[4] || (_cache[4] = ($event: any) => (_ctx.opened()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractsList(_ctx.contractsVariables?.data.data).value, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `inputWrap-${i}`,
              class: "modalNewContract__inputWrap"
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.pickedValue) = $event)),
                value: item?.period,
                id: `contractPeriod-${i}`,
                type: "radio",
                name: "contractPeriod",
                class: "modalNewContract__input",
                checked: item?.period === _ctx.formData.pickedValue && item?.sum_amount !== 0,
                disabled: item?.limit_amount === 0 && !_ctx.formData.isStake
              }, null, 8, _hoisted_3), [
                [_vModelRadio, _ctx.formData.pickedValue]
              ]),
              _createElementVNode("label", {
                for: `contractPeriod-${i}`,
                class: _normalizeClass([
            'modalNewContract__label',
            {
              modalNewContract__label_first: i === 0,
              modalNewContract__label_last: i === _ctx.periodsList.length - 1,
              modalNewContract__label_disable: item?.sum_amount === 0,
            },
          ])
              }, _toDisplayString(`${item?.period} month`), 11, _hoisted_4)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_CommonInput, {
            modelValue: _ctx.formData.amount,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.amount) = $event)),
            maxlength: "10",
            required: "",
            style: 'font-weight: bold;',
            onKeypress: _ctx.amountKeydown,
            onInput: _ctx.clearUnNumberValuePerPaste,
            class: "modalNewContract__amount"
          }, _createSlots({
            default: _withCtx(() => [
              _cache[7] || (_cache[7] = _createTextVNode(" Amount ")),
              ((_ctx.isNodeContractAmountLessInputAmount(_ctx.numberOfNodes?.data.data).value) ||
            (_ctx.errorForStake && _ctx.formData.amount !== undefined)
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(`Minimum deposit amount
            ${!_ctx.formData.isStake
                ? _ctx.dividingNumber(_ctx.nodeContractAmount(_ctx.numberOfNodes?.data.data).value?.min_amount)
                : "50000000"
              } FLC`), 1))
                : (!_ctx.formData.isStake &&
            _ctx.isErrorInput(
              _ctx.contractsList(_ctx.contractsVariables?.data.data).value,
              _ctx.numberOfNodes?.data.data,
              _ctx.userNodes?.data.data
            ).value
          )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, " The amount exceeds the scope of the contract "))
                  : _createCommentVNode("", true)
            ]),
            _: 2
          }, [
            (!_ctx.formData.isStake)
              ? {
                  name: "suffix",
                  fn: _withCtx(() => [
                    _cache[5] || (_cache[5] = _createTextVNode("FLIGHT"))
                  ]),
                  key: "0"
                }
              : {
                  name: "suffix",
                  fn: _withCtx(() => [
                    _cache[6] || (_cache[6] = _createTextVNode("$"))
                  ]),
                  key: "1"
                }
          ]), 1032, ["modelValue", "onKeypress", "onInput"]),
          (!_ctx.formData.isStake)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "i" }, "=", -1)),
                _createTextVNode(_toDisplayString(+_ctx.sumInUsd.toFixed(8)) + " $", 1),
                _createElementVNode("span", _hoisted_9, "1 FLIGHT = " + _toDisplayString(_ctx.oneFlcInUsd) + " $", 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "i" }, "=", -1)),
                _createTextVNode(_toDisplayString(+_ctx.sumInFlc.toFixed(8)) + " FLIGHT", 1),
                _createElementVNode("span", _hoisted_11, "1 $ = " + _toDisplayString(_ctx.oneUsdInFlc.toFixed(8)) + " FLIGHT", 1)
              ]))
        ]),
        _createVNode(_component_CommonButtonPrimary, {
          class: "modalNewContract__button",
          disabled: (_ctx.isErrorInput(
        _ctx.contractsList(_ctx.contractsVariables?.data.data).value,
        _ctx.numberOfNodes?.data.data,
        _ctx.userNodes?.data.data
      ).value &&
        _ctx.formData.isStake) ||
        _ctx.formData.amount === undefined ||
        _ctx.errorForStake ||
        (_ctx.isNodeContractAmountLessInputAmount(_ctx.numberOfNodes?.data.data).value && !_ctx.formData.isStake)
        ,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.buyContract(_ctx.contractsList(_ctx.contractsVariables?.data.data).value)))
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" Create")
          ])),
          _: 1
        }, 8, ["disabled"]),
        (_ctx.formData.isStake)
          ? (_openBlock(), _createBlock(_component_CommonButtonPrimary, {
              key: 0,
              class: "modalNewContract__button modalNewContract__buttonRenewal",
              disabled: (_ctx.isErrorInput(
          _ctx.contractsList(_ctx.contractsVariables?.data.data).value,
          _ctx.numberOfNodes?.data.data,
          _ctx.userNodes?.data.data
        ).value) || _ctx.formData.amount
          ,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.buyContract(_ctx.contractsList(_ctx.contractsVariables?.data.data).value, true)))
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode(" Legacy Renewal Pack ")
              ])),
              _: 1
            }, 8, ["disabled"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["name", "onCloseModal"]))
}