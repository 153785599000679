import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modalCreateServer"
}
const _hoisted_2 = { class: "modalCreateServer__addressBox" }
const _hoisted_3 = { class: "modalCreateServer__value" }
const _hoisted_4 = { class: "modalCreateServer__infoBox" }
const _hoisted_5 = { class: "modalCreateServer__title" }
const _hoisted_6 = { class: "modalCreateServer__value" }
const _hoisted_7 = { class: "modalCreateServer__qr" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "modalCreateServer__hashInfoBox" }
const _hoisted_10 = {
  key: 0,
  class: "inputErrorInfo"
}
const _hoisted_11 = {
  key: 1,
  class: "inputErrorInfo"
}
const _hoisted_12 = {
  key: 2,
  class: "inputErrorInfo"
}
const _hoisted_13 = {
  key: 3,
  class: "modalCreateServer__info"
}
const _hoisted_14 = { class: "modalCreateServer__inputBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonLoader = _resolveComponent("CommonLoader")!
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: 'Create server',
    isModalCreateServer: true,
    onOpened: _ctx.opened,
    onCloseModal: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonLoader, {
        active: !_ctx.isShowModal,
        class: "loader"
      }, null, 8, ["active"]),
      (_ctx.isShowModal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "modalCreateServer__title" }, "Address", -1)),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.formData.getWallet?.address), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paidInfo, (item, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `infoElem-${item.title}`,
                  class: _normalizeClass(`modalCreateServer__infoElem-${i}`)
                }, [
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(item.title), 1),
                  _createElementVNode("p", _hoisted_6, _toDisplayString(item.value(_ctx.formData.getWallet)), 1)
                ], 2))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("img", {
                class: "modalCreateServer__qrCode",
                src: _ctx.formData.getWallet?.image,
                alt: ""
              }, null, 8, _hoisted_8)
            ]),
            _createElementVNode("div", _hoisted_9, [
              (_ctx.takeContractBlockServerPeriod(_ctx.contractsList?.data.data).value && +_ctx.takeContractBlockServerPeriod(_ctx.contractsList?.data.data).value > _ctx.timeNow)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(`Exceeded the number of attempts to send the transaction hash. The next opportunity is open on
          ${_ctx.dateDay(_ctx.takeContractBlockServerPeriod(_ctx.contractsList?.data.data).value)}
                    at ${_ctx.dateHour(_ctx.takeContractBlockServerPeriod(_ctx.contractsList?.data.data).value)}`), 1))
                : (_ctx.formData.isTryingEnterHash && _ctx.formData.getWallet?.count_error !== 4)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(`Incorrect transaction hash. After ${_ctx.formData.remainingAttempts} incorrect attempts, the form will be
          locked
          for 24 hours.`), 1))
                  : (_ctx.formData.getWallet?.count_error !== 4)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(`After ${_ctx.formData.getWallet?.count_error} incorrect attempts, the form will be locked for 24 hours.`), 1))
                    : (_openBlock(), _createElementBlock("p", _hoisted_13, " After the transfer, enter the transaction hash."))
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_CommonInput, {
                disabled: _ctx.takeContractBlockServerPeriod(_ctx.contractsList?.data.data).value && _ctx.takeContractBlockServerPeriod(_ctx.contractsList?.data.data).value > _ctx.timeNow,
                modelValue: _ctx.formData.hash,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.hash) = $event)),
                required: "",
                style: 'font-weight: bold;'
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Transaction hash ")
                ])),
                _: 1
              }, 8, ["disabled", "modelValue"])
            ]),
            _createVNode(_component_CommonButtonPrimary, {
              disabled: _ctx.takeContractBlockServerPeriod(_ctx.contractsList?.data.data).value && _ctx.takeContractBlockServerPeriod(_ctx.contractsList?.data.data).value > _ctx.timeNow,
              class: "modalCreateServer__button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createServer()))
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("I paid")
              ])),
              _: 1
            }, 8, ["disabled"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "onOpened", "onCloseModal"]))
}