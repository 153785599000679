import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inputs" }
const _hoisted_2 = { class: "balances" }
const _hoisted_3 = { class: "balance" }
const _hoisted_4 = { class: "balance__num" }
const _hoisted_5 = { class: "inputsBox" }
const _hoisted_6 = {
  class: "inputItem fluid",
  key: 'bep20'
}
const _hoisted_7 = {
  key: 0,
  class: "inputErrorInfo"
}
const _hoisted_8 = { class: "sumBox" }
const _hoisted_9 = { class: "inputItem" }
const _hoisted_10 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: _ctx.$t('Withdraw from FLIGHT balance')
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
      }, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "infoBlockBeginning" }, [
          _createElementVNode("p", { class: "infoBlockBeginning__text" }, " Attention! Funds can be withdrawn only to a Binance Smart Chain (BEP20) wallet. Please make sure that you’ve specified the correct address and that this address supports the BEP20 network. If the chosen platform does not support the recovery of funds, you will lose your assets. Please note that Flight Clup ceases to be responsible for the safety of your funds after the withdrawal of tokens to third-party wallets. ")
        ], -1)),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "balance__title" }, " Available for withdrawal ", -1)),
              _createElementVNode("p", _hoisted_4, [
                _createTextVNode(_toDisplayString(_ctx.availableBalance) + " ", 1),
                _cache[2] || (_cache[2] = _createElementVNode("span", null, "FLIGHT", -1))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_CommonInput, _mergeProps(_ctx.form.bep20, {
              modelValue: _ctx.bep20,
              disabled: "",
              required: "",
              hasError: !_ctx.bep20
            }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.bep20.label) + " ", 1),
                (!_ctx.bep20)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                      _cache[5] || (_cache[5] = _createTextVNode(" Please fill your BEP20-address in ")),
                      _createVNode(_component_router_link, { to: "/profile" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" profile ")
                        ])),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 16, ["modelValue", "hasError"])
          ]))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_CommonInput, {
              fontWeight: "700",
              modelValue: _ctx.data.sum,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange('sum', $event))),
              hasError: !_ctx.isValidSum,
              required: "",
              mask: "#*"
            }, {
              suffix: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("FLIGHT")
              ])),
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Withdrawal amount")) + " ", 1)
              ]),
              _: 1
            }, 8, ["modelValue", "hasError"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_CommonButtonPrimary, {
            type: "submit",
            disabled: !_ctx.isValidSum
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Withdraw " + _toDisplayString(_ctx.data.sum || 0) + " FLIGHT ", 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "infoBlock" }, " Payouts can take up to 72 hours. ", -1))
      ], 32)
    ]),
    _: 1
  }, 8, ["name", "title"]))
}