import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "spinnerBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonSpinner = _resolveComponent("CommonSpinner")!

  return (_ctx.state === _ctx.$status.PENDING || _ctx.state === _ctx.$status.INIT || _ctx.active)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['commonLoader', { placeholder: _ctx.isPlaceholder, top: _ctx.top }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_CommonSpinner)
        ])
      ], 2))
    : _createCommentVNode("", true)
}