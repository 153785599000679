import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "buttonBox" }
const _hoisted_2 = { class: "buttonItem" }
const _hoisted_3 = { class: "buttonItem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonButtonSecondary = _resolveComponent("CommonButtonSecondary")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    visible: _ctx.isOpen,
    title: _ctx.$t('Confirm action'),
    onCloseModal: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CommonButtonSecondary, { onClick: _ctx.onCancel }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CommonButtonPrimary, { onClick: _ctx.onAccept }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Accept")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "title", "onCloseModal"]))
}