import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, {
    modelValue: _ctx.visible || _ctx.isOpen,
    onCancel: _ctx.closeHandler,
    onClickOutside: _ctx.closeHandler,
    maxWidth: _ctx.width,
    class: _normalizeClass({ 'hardwareWallet-content': _ctx.hardwareWalletContent }),
    styles: {
      overflow: 'auto',
    },
    classes: { modalContainer: _ctx.isTopUpModal || _ctx.isModalNewContract || _ctx.isModalCreateServer },
    "overlay-class": "modalOverlay",
    "content-class": ['modalContent', {
      modalContent_forTopUp: _ctx.isTopUpModal,
      modalContent_forNewContract: _ctx.isModalNewContract,
      modalContent_forCreateServer: _ctx.isModalCreateServer,
      modalContent_forProductDetails: _ctx.isProductDetails,
    }]
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: "closeButton",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeHandler && _ctx.closeHandler(...args)))
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("svg", { class: "closeIcon" }, [
          _createElementVNode("use", { "xlink:href": "#close-icon--sprite" })
        ], -1)
      ])),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 8, ["modelValue", "onCancel", "onClickOutside", "maxWidth", "class", "classes", "content-class"]))
}