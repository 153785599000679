import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "desc" }
const _hoisted_2 = { class: "inputBox" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "buttonBox" }
const _hoisted_5 = { class: "buttonItem" }
const _hoisted_6 = { class: "buttonItem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonButtonSecondary = _resolveComponent("CommonButtonSecondary")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    visible: _ctx.isOpen,
    onCloseModal: _ctx.closeHandler,
    title: _ctx.isTwoAuth ? _ctx.$t('Two-factor authentication') : 'Confirm action',
    onOpened: _ctx.opened
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
        class: "body"
      }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(
              _ctx.isTwoAuth
                ? "Enter 2FA code to confirm the operation."
                : "Enter the password to confirm the operation."
            )), 1),
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.isTwoAuth && _ctx.userMail)
            ? (_openBlock(), _createElementBlock("input", {
                key: 0,
                type: "text",
                name: "email",
                value: _ctx.userMail,
                autocomplete: "username email",
                style: {"display":"none"}
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createVNode(_component_CommonInput, _mergeProps({
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange($event))),
            required: true,
            onInnerRef: _ctx.setInputRef
          }, _ctx.dynamicInputProps), null, 16, ["modelValue", "onInnerRef"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_CommonButtonSecondary, {
              type: "button",
              onClick: _withModifiers(_ctx.closeHandler, ["prevent","stop"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_CommonButtonPrimary, { type: "submit" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Accept")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["visible", "onCloseModal", "title", "onOpened"]))
}