

import { defineComponent, reactive, computed } from 'vue';
import '@/assets/icons/close-icon.svg?sprite';
import { api } from '@/utils/apiInstance';
import store from '@/store';
import { basicOfficeFetch } from '@/store/storeUtils';
import { useToast } from 'vue-toastification';
import useSWRV from 'swrv';
import { modalsNames } from '@/utils/modals';
import CommonModal from './CommonModal.vue';
import CommonInput from './CommonInput.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';

export default defineComponent({
  name: 'modalTopUp',
  components: { CommonModal, CommonInput, CommonButtonPrimary },
  setup() {
    const toast = useToast();
    const modalName = modalsNames.modalTopUp;

    const formData = reactive({
      amount: undefined,
      state: 'INIT',
      isStake: false,
    });

    const balanceData = computed(() => store.getters.userDashboardStore.data);

    const oneFlcInUsd = computed(() => balanceData.value?.flcToUsdtRate || 0);
    const oneUsdInFlc = computed(() => 1 / Number(oneFlcInUsd.value));

    const sumInUsd = computed(() => Number(oneFlcInUsd.value) * Number(formData.amount || 0));
    const sumInFlc = computed(() => Number(oneUsdInFlc.value) * Number(formData.amount || 0));
    const renewalSumInFlc = computed(() => Number(oneUsdInFlc.value) * 1000);

    const takeHashValue = (value: any) => {
      const hashValue = window.location.hash.split('&').find((item) => item.split('=')[0] === value);
      if (hashValue) {
        return hashValue.split('=')[1];
      }
      return '';
    };

    const opened = () => {
      formData.isStake = Boolean(takeHashValue('isStake'));
    };

    const topUpContract = (isRenewal = false) => {
      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: formData.isStake ? '/stake/store' : '/contracts/store',
          data: formData.isStake ? {
            item: {
              config_stake_id: String(takeHashValue('config_stake_id')),
              amount: isRenewal ? String(renewalSumInFlc.value).replace(',', '.') : String(sumInFlc.value).replace(',', '.'),
              app: 'stake-contract',
              id: 'stake-contract',
              user_stake_id: String(takeHashValue('user_contract_id')),
              packet_id: String(takeHashValue('packet_id')),
              renewal: isRenewal,
            },
          } : {
            item: {
              contract_id: String(takeHashValue('contract_id')), amount: String(formData.amount).replace(',', '.'), app: 'contract', id: 1,
            },
            node_id: String(takeHashValue('id')),
            user_contract_id: String(takeHashValue('user_contract_id')),
          },
        },
        setState: ({ fetchState }) => {
          formData.state = fetchState;
        },
        callback: (fetchState, data) => {
          if (fetchState === 'FULFILLED') {
            if (typeof data.data.data[0] === 'string' && data.data.data[0].includes('https://')) {
              window.location.assign(data.data.data[0]);
            }
          }
          if (fetchState === 'REJECTED') {
            toast.warning('Something went wrong');
          }
        },
      });
    };

    const closeModal = () => {
      formData.amount = undefined;
    };

    const contractsList = useSWRV('/contracts/userContracts', null)?.data;

    const takeContractAmount = (list: any) => computed(() => (list || []).find((item: any) => item.id === Number(takeHashValue('contract_id')))?.limit_amount);

    const isDisableButton = (list: any) => computed(() => takeContractAmount(list).value < Number(String(formData.amount).replace(',', '.')));

    const amountKeydown = (e: any) => {
      const keys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
      if ((/\+|-|=|_/.test(e.key) || !/\.|,|[0-9]/g.test(e.key)) && !(keys.includes(e.key))) {
        e.preventDefault();
      }
    };
    const clearUnNumberValuePerPaste = (e: any) => {
      e.target.value = String(e.target.value).replaceAll(' ', '');
      if (!Number(String(e.target.value).replace(',', '.'))) {
        formData.amount = undefined;
      }
    };

    return {
      clearUnNumberValuePerPaste,
      amountKeydown,
      contractsList,
      isDisableButton,
      closeModal,
      takeHashValue,
      modalName,
      formData,
      topUpContract,
      sumInUsd,
      oneFlcInUsd,
      oneUsdInFlc,
      sumInFlc,
      opened,
    };
  },
});
