import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modalConfirmationEmail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: `To confirm the new email, we have sent a confirmation link to ${_ctx.email}.`
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CommonButtonPrimary, {
          onClick: _ctx.onClose,
          class: "modalNewEmail__button",
          disabled: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Great!')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["name", "title"]))
}